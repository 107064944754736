.breadcrumb {

  a {
    @extend .text-overflow;
  }

  li:not(.active):not(:last-child) a {
    max-width: 150px;
  }
}
