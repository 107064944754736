@if $app-default-font == $font-family-base {
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    //noinspection CssUnknownTarget
    src: url(../fonts/Open-Sans.woff2) format('woff2');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    //noinspection CssUnknownTarget
    src: url(../fonts/Open-Sans.woff2) format('woff2');
  }
}