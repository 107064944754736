/**
 * Alerts.
 */
.alert {

  ul {
    margin: $alert-padding-y $alert-padding-x 0 0;
    list-style-type: square;
  }

  li {
    padding-bottom: $alert-padding-y;
    font-weight: normal;
  }
}

.alert-error {
  @extend .alert-danger;
}

.alert-info {
  background-color: $info;
  color: $app-info-text;
}

/*
Bootbox.
 */
.bootbox-body {

  // Yii2 Response is sending AJAX errors wrapped in <pre>
  pre {
    font: inherit;
    overflow: initial;
    white-space: inherit;
    font-weight: $font-weight-bold;
  }
}