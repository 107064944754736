body {
  padding: $app-body-padding;
  overflow-y: scroll;
}

.page-header {
  margin: $app-header-margin;
  padding-bottom: $app-header-padding-bottom;
  text-align: $app-header-text-align;
  border-bottom: $app-header-border-bottom;
  line-height: $app-header-line-height;

  a:hover {
    text-decoration: none;
  }
}