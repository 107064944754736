//
// Basic Bootstrap table
//

.table {
  width: 100%;
  margin-bottom: $app-table-margin-bottom;
  background-color: $table-bg; // Reset for nesting within parents with `background-color`.

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    border-top: $table-border-width solid $table-border-color;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: $app-table-th-border-width solid $app-table-th-border-color;
  }

  tbody + tbody {
    border-top: $app-table-th-border-width solid $table-border-color;
  }

  .table {
    background-color: $body-bg;
  }
}

//
////
//// Condensed table w/ half padding
////
//
//.table-sm {
//	th,
//	td {
//		padding: $table-cell-padding-sm;
//	}
//}

//
//// Border versions
////
//// Add or remove borders all around the table and between all the columns.
//
//.table-bordered {
//	border: $table-border-width solid $table-border-color;
//
//	th,
//	td {
//		border: $table-border-width solid $table-border-color;
//	}
//
//	thead {
//		th,
//		td {
//			border-bottom-width: (2 * $table-border-width);
//		}
//	}
//}
//
//.table-borderless {
//	th,
//	td,
//	thead th,
//	tbody + tbody {
//		border: 0;
//	}
//}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $table-accent-bg;
  }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  tbody tr {
    @include hover {
      background-color: $table-hover-bg;
    }
  }
}


// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

@each $color, $value in $theme-colors {
  @include table-row-variant($color, theme-color-level($color, -9));
}

@include table-row-variant(active, $table-active-bg);


// Dark styles
//
// Same table markup, but inverted color scheme: dark background and light text.

// stylelint-disable-next-line no-duplicate-selectors
//.table {
//	.thead-dark {
//		th {
//			color: $table-dark-color;
//			background-color: $table-dark-bg;
//			border-color: $table-dark-border-color;
//		}
//	}
//
//	.thead-light {
//		th {
//			color: $table-head-color;
//			background-color: $table-head-bg;
//			border-color: $table-border-color;
//		}
//	}
//}

//.table-dark {
//	color: $table-dark-color;
//	background-color: $table-dark-bg;
//
//	th,
//	td,
//	thead th {
//		border-color: $table-dark-border-color;
//	}
//
//	&.table-bordered {
//		border: 0;
//	}
//
//	&.table-striped {
//		tbody tr:nth-of-type(odd) {
//			background-color: $table-dark-accent-bg;
//		}
//	}
//
//	&.table-hover {
//		tbody tr {
//			@include hover {
//				background-color: $table-dark-hover-bg;
//			}
//		}
//	}
//}


// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

//.table-responsive {
//	@each $breakpoint in map-keys($grid-breakpoints) {
//		$next: breakpoint-next($breakpoint, $grid-breakpoints);
//		$infix: breakpoint-infix($next, $grid-breakpoints);
//
//		&#{$infix} {
//			@include media-breakpoint-down($breakpoint) {
//				display: block;
//				width: 100%;
//				overflow-x: auto;
//				-webkit-overflow-scrolling: touch;
//				-ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057
//
//				// Prevent double border on horizontal scroll due to use of `display: block;`
//				> .table-bordered {
//					border: 0;
//				}
//			}
//		}
//	}
//}


.table-vertical td {
  vertical-align: middle;
}

th > a, th > a:hover {
  display: block;
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  white-space: nowrap;
}

th > a.asc, th > a.desc {
  position: relative;
  font-style: $app-table-th-active-font-style;
  text-decoration: $app-table-th-active-text-decoration;
}

tr.is-selected {
  background-color: $app-table-th-selected-bg !important;

  & + .is-selected td {
    border-color: $card-bg;
  }
}

.is-selected .is-selected-hidden, tr:not(.is-selected) .is-selected-block {
  display: none;
}

tr.disabled {
  opacity: .3;
}

tr.invalid {
  text-decoration: line-through;
}

a.asc:after, a.desc:after {
  display: inline-block;
  margin: 0 0 0.2em 0.5em;
  width: 0;
  height: 0;
  border-left: 0.4em solid transparent;
  border-right: 0.4em solid transparent;
  border-bottom: 0.4em solid #000;
  content: "";
}

a.desc:after {
  border-top: 0.4em solid #000;
  border-bottom: 0;
}