
.dropdown, .dropup {

  & > .btn {
    width: 100%;
    background-color: $app-dropdown-button-bg;
    color: $app-dropdown-button-color;
    border-color: $app-dropdown-button-border-color;
    @extend .text-overflow;
  }

  &.is-active > .btn {
    background-color: $app-dropdown-button-active-bg;
    color: $app-dropdown-button-active-color;
    border-color: $app-dropdown-button-active-border-color;
  }

  & > .btn-submit {
    background-color: $btn-primary-bg;
    color: color-yiq($btn-primary-bg);
    border-color: $btn-primary-border-color;
  }

  .grid-view-header & {
    width: 100%;
  }
}


.dropdown-menu {
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;

  & > a {
    @extend .text-overflow;
  }
}

.dropdown-header {
  padding: $app-dropdown-header-padding-y $app-dropdown-header-padding-x;
  font-size: inherit;
  font-weight: normal;
  cursor: default;
}