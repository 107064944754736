/*
Admin.
 */
@import "includes/variables";
@import "includes/fonts";
@import "../../../vendor/npm-asset/bootstrap/scss/functions";
@import "../../../vendor/npm-asset/bootstrap/scss/variables";
@import "../../../vendor/npm-asset/bootstrap/scss/mixins";
//@import "../../../vendor/npm-asset/bootstrap/scss/bootstrap";
////@import "../../../vendor/npm-asset/bootstrap/scss/root";
@import "../../../vendor/npm-asset/bootstrap/scss/reboot";
@import "includes/reboot";
@import "../../../vendor/npm-asset/bootstrap/scss/type";
@import "includes/type";
@import "../../../vendor/npm-asset/bootstrap/scss/images";
@import "includes/images";
////@import "../../../vendor/npm-asset/bootstrap/scss/code";
@import "../../../vendor/npm-asset/bootstrap/scss/grid";
@import "includes/grid";
////@import "../../../vendor/npm-asset/bootstrap/scss/tables";
@import "includes/tables";
////@import "../../../vendor/npm-asset/bootstrap/scss/forms";
@import "includes/forms";
////@import "../../../vendor/npm-asset/bootstrap/scss/buttons";
@import "includes/buttons";
@import "../../../vendor/npm-asset/bootstrap/scss/transitions";
@import "../../../vendor/npm-asset/bootstrap/scss/dropdown";
@import "includes/dropdown";
@import "../../../vendor/npm-asset/bootstrap/scss/button-group";
@import "../../../vendor/npm-asset/bootstrap/scss/input-group";
////@import "../../../vendor/npm-asset/bootstrap/scss/custom-forms";
@import "../../../vendor/npm-asset/bootstrap/scss/nav";
@import "includes/nav";
////@import "../../../vendor/npm-asset/bootstrap/scss/navbar";
@import "includes/navbar";
@import "../../../vendor/npm-asset/bootstrap/scss/card";
@import "includes/card";
@import "../../../vendor/npm-asset/bootstrap/scss/breadcrumb";
@import "includes/breadcrumb";
////@import "../../../vendor/npm-asset/bootstrap/scss/pagination";
@import "includes/pagination";
////@import "../../../vendor/npm-asset/bootstrap/scss/badge";
@import "includes/badge";
////@import "../../../vendor/npm-asset/bootstrap/scss/jumbotron";
@import "../../../vendor/npm-asset/bootstrap/scss/alert";
@import "includes/alert";
////@import "../../../vendor/npm-asset/bootstrap/scss/progress";
////@import "../../../vendor/npm-asset/bootstrap/scss/media";
@import "../../../vendor/npm-asset/bootstrap/scss/list-group";
@import "../../../vendor/npm-asset/bootstrap/scss/close";
@import "../../../vendor/npm-asset/bootstrap/scss/modal";
@import "../../../vendor/npm-asset/bootstrap/scss/tooltip";
////@import "../../../vendor/npm-asset/bootstrap/scss/popover";
////@import "../../../vendor/npm-asset/bootstrap/scss/carousel";
//
////@import "../../../vendor/npm-asset/bootstrap/scss/utilities/align";
@import "../../../vendor/npm-asset/bootstrap/scss/utilities/background";
////@import "../../../vendor/npm-asset/bootstrap/scss/utilities/borders";
@import "../../../vendor/npm-asset/bootstrap/scss/utilities/clearfix";
@import "../../../vendor/npm-asset/bootstrap/scss/utilities/display";
@import "../../../vendor/npm-asset/bootstrap/scss/utilities/embed";
@import "../../../vendor/npm-asset/bootstrap/scss/utilities/flex";
////@import "../../../vendor/npm-asset/bootstrap/scss/utilities/float";
@import "../../../vendor/npm-asset/bootstrap/scss/utilities/position";
@import "../../../vendor/npm-asset/bootstrap/scss/utilities/screenreaders";
@import "../../../vendor/npm-asset/bootstrap/scss/utilities/shadows";
@import "../../../vendor/npm-asset/bootstrap/scss/utilities/sizing";
////@import "../../../vendor/npm-asset/bootstrap/scss/utilities/spacing";
//@import "../../../vendor/npm-asset/bootstrap/scss/utilities/text";
@import "includes/text";
@import "../../../vendor/npm-asset/bootstrap/scss/utilities/visibility";
//
@import "includes/layout";
@import "includes/toolbar";
@import "includes/jquery";
@import "includes/language";
@import "includes/trail";
//
@import "../../../vendor/npm-asset/bootstrap/scss/print";



