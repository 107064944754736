.visible-if-empty {
  display: none;

  &:last-child {
    display: block;
  }
}

.hidden-if-empty {
  display: block;

  &:last-child {
    display: none;
  }
}

.hidden-if-inactive {
  display: none;

  &.active {
    display: block;
  }
}

/**
 * Row grid.
 */
.grid-view-header {
  margin-bottom: $app-grid-view-margin-y;
  border-bottom: $app-grid-view-border;

  [class*="col"] {
    padding-bottom: $app-grid-view-margin-y;
  }

  &:empty {
    display: none;
  }
}

.grid-view-buttons {
  margin-top: $app-grid-view-buttons-top;
}

.grid-view-footer {
  position: $app-grid-view-footer-position;
  margin-top: $app-grid-view-margin-y;
  border-top: $app-grid-view-border;

  @if ($app-grid-view-footer-position == 'sticky') {
    bottom: 0;
    background-color: $card-bg;
    padding-bottom: $card-spacer-y;
  }

  [class*="col"] {
    padding-top: $app-grid-view-margin-y;
  }
}