.toolbar{
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: $app-toolbar-background;
  border-top: $app-toolbar-border;
  z-index: $zindex-fixed;

  .btn-toolbar{
    padding: $app-toolbar-padding-y $app-toolbar-padding-x;
  }
}