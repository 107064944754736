@each $color, $value in $theme-colors {
  @include text-emphasis-variant(".text-#{$color}", $value, true);
}

.text-muted {
  color: $text-muted !important;

  a {
    color: inherit;
  }
}

.text-nowrap {
  white-space: nowrap;
}

.text-break {
  word-break: break-word !important; // Deprecated, but avoids issues with flex containers
  word-wrap: break-word !important; // Used instead of `overflow-wrap` for IE & Edge Legacy
}