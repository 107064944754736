.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.icon-left {
  margin-right: $app-icon-margin-x;
}

.icon-text {
  span{
    display: inline-block;
    margin-left: $app-icon-margin-x;
  }
}

.list-unstyled:last-child {
  margin-bottom: 0;
}

.strong {
  font-weight: bold;
}

//@each $color, $value in $theme-colors {
//  @include text-emphasis-variant(".text-#{$color}", $value);
//}