/**
 * Base scaffolding declarations.
 */
img {
  max-width: 100%;
}


.img-responsive {
  display: block;
  width: 100%;
}