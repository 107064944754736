.i18n-dropdown {

  .caret {
    display: none;
  }

  .dropdown-menu {
    left: 0;
    right: auto;
  }
}

//noinspection ALL
.i18n-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: no-repeat;
  background-size: contain;
  vertical-align: middle;

  &.de {
    background-image: url(../images/flags/de.png);
  }

  &.en-US {
    background-image: url(../images/flags/en-US.png);
  }

  &.fr {
    background-image: url(../images/flags/fr.png);
  }

  &.pt {
    background-image: url(../images/flags/pt.png);
  }

  &.zh-CN {
    background-image: url(../images/flags/zh-CN.png);
  }

  &.zh-TW {
    background-image: url(../images/flags/zh-TW.png);
  }
}

.i18n-label {
  margin-left: .5em;
}